export interface ApplicationFieldOption {
    text: string;
    value: string;
}

export enum ApplicationFieldType {
    TEXT,
    MULTILINE,
    CHECKBOXES,
    SELECT,
    FLAG,
    INFO_BLOCK
}

export interface ApplicationField {
    key: string;
    text: string;
    type: ApplicationFieldType;
    options?: ApplicationFieldOption[];
    description?: string;
    filterRegex?: RegExp;
    validationRegex?: RegExp;
    neq?: string;
}

export interface ApplicationFieldGroup {
    text: string;
    key: string;
    fields: ApplicationField[];
    hideOnFlag?: string;
}

const REGEX_NON_EMPTY = /.+/m;
const REGEX_SSN = /\d{8}-\d{4}/m;
const REGEX_ZIPCODE = /\d{5}/m;
const REGEX_EMAIL = /.+@.+\..+/m;

const REGEX_FILTER_DIGITS = /\d/m;
const REGEX_FILTER_SSN = /[\d-]/m;

export const APPLICATION_FIELD_GROUPS: ApplicationFieldGroup[] = [
    {
        text: "Barnet",
        key: "attendee",
        fields: [
            {
                key: "periodPrimaryIndex",
                text: "Önskat förstaval",
                type: ApplicationFieldType.SELECT,
                options: [
                    {text: "Period 1", value: "1"},
                    {text: "Period 2", value: "2"},
                    {text: "Period 3", value: "3"},
                ],
                validationRegex: REGEX_NON_EMPTY,
            },
            {
                key: "periodSecondaryIndex",
                text: "Ev. Alternativ",
                type: ApplicationFieldType.SELECT,
                options: [
                    {text: "Ingen", value: "0"},
                    {text: "Period 1", value: "1"},
                    {text: "Period 2", value: "2"},
                    {text: "Period 3", value: "3"},
                ],
                validationRegex: REGEX_NON_EMPTY,
                neq: "periodPrimaryIndex"
            },
            {
                key: "applicationYears",
                text: "Jag har tidigare ansökt om plats men ej kommit med",
                type: ApplicationFieldType.CHECKBOXES,
                options: [
                    {text: "2024", value: "2024"},
                    {text: "2023", value: "2023"},
                    {text: "2022", value: "2022"},
                    {text: "2021", value: "2021"},
                    {text: "2020", value: "2020"},
                    {text: "2019", value: "2019"},
                    {text: "2018", value: "2018"},
                    {text: "2017", value: "2017"},
                    {text: "2016", value: "2016"},
                    {text: "2015", value: "2015"},
                ],
            },
            {
                key: "attendanceYears",
                text: "Jag har tidigare varit med på Säljö Barnkoloni",
                type: ApplicationFieldType.CHECKBOXES,
                options: [
                    {text: "2024", value: "2024"},
                    {text: "2023", value: "2023"},
                    {text: "2022", value: "2022"},
                    {text: "2021", value: "2021"},
                    {text: "2020", value: "2020"},
                    {text: "2019", value: "2019"},
                    {text: "2018", value: "2018"},
                    {text: "2017", value: "2017"},
                    {text: "2016", value: "2016"},
                    {text: "2015", value: "2015"},
                ],
            },

            {
                key: "firstName",
                text: "Förnamn",
                type: ApplicationFieldType.TEXT,
                validationRegex: REGEX_NON_EMPTY
            },
            {
                key: "lastName",
                text: "Efternamn",
                type: ApplicationFieldType.TEXT,
                validationRegex: REGEX_NON_EMPTY
            },
            {
                key: "ssn",
                text: "Personnummer",
                type: ApplicationFieldType.TEXT,
                description: "Tolv siffror med bindestreck. Ex: 20000101-0000.",
                filterRegex: REGEX_FILTER_SSN,
                validationRegex: REGEX_SSN,
            },

            {key: "address", text: "Adress", type: ApplicationFieldType.TEXT, validationRegex: REGEX_NON_EMPTY},
            {key: "city", text: "Ort", type: ApplicationFieldType.TEXT, validationRegex: REGEX_NON_EMPTY},
            {
                key: "zipCode",
                text: "Postnummer",
                type: ApplicationFieldType.TEXT,
                filterRegex: REGEX_FILTER_DIGITS,
                validationRegex: REGEX_ZIPCODE,
            },

            {
                key: "school",
                text: "Skola/Skolklass",
                type: ApplicationFieldType.TEXT,
                validationRegex: REGEX_NON_EMPTY,
            },
            {
                key: "shirtSize",
                text: "Välj Tröjstorlek",
                type: ApplicationFieldType.SELECT,
                options: [
                    {text: "120", value: "120"},
                    {text: "140", value: "140"},
                    {text: "160", value: "160"},
                    {text: "S", value: "S"},
                    {text: "M", value: "M"},
                    {text: "L", value: "L"},
                    {text: "XL", value: "XL"},
                ],
                description: "I år bjuder Majblomman alla barn på Säljötröjan.",
                validationRegex: REGEX_NON_EMPTY,
            },

            {
                key: "medicalInfo",
                text: "Allergi/Sjukdom/Medicinering",
                type: ApplicationFieldType.MULTILINE,
                description: "Här vill vi att Du skriver om Ditt barn är allergiskt mot något, lider av någon sjukdom eller tar någon medicin regelbundet.",
            },
            {
                key: "extraInfo",
                text: "Övrig information som kan ha betydelse för behovet av sommarvistelse",
                type: ApplicationFieldType.MULTILINE,
            },

            {
                key: "consentSocialMedia",
                text: "Vi godkänner att bilder på vårat barn får synas på Säljö Barnkolonis sociala medier",
                type: ApplicationFieldType.SELECT,
                options: [
                    {text: "Ja", value: "1"},
                    {text: "Nej", value: "0"},
                ],
                validationRegex: REGEX_NON_EMPTY,
            },
            {
                key: "consentWeb",
                text: "Vi godkänner att bilder på vårat barn får synas på Säljö Barnkolonis hemsida",
                type: ApplicationFieldType.SELECT,
                options: [
                    {text: "Ja", value: "1"},
                    {text: "Nej", value: "0"},
                ],
                validationRegex: REGEX_NON_EMPTY
            },

            {
                key: "sendApplicationCopy",
                text: "Jag vill ha ett e-post med all ifylld information",
                type: ApplicationFieldType.SELECT,
                options: [
                    {text: "Nej, jag vill bara ha en bekräftelse.", value: "0"},
                    {text: "Ja", value: "1"},
                ],
                description: "Du kommer att få en bekräftelse på att vi mottagit ditt barns ansökan. Detta e-post innehåller inga personuppgifter förutom barnets förnamn.\nDu kan själv aktivt välja att få ett e-post med all ifylld information och accepterar då att Cajam AB levererar personuppgifterna\nvia e-post till den adress som är ifylld under Vårdnadshavare 1.",
                validationRegex: REGEX_NON_EMPTY,
            },
            {
                key: "invoiceMethod",
                text: "Om mitt barn kommer med på sommarkollo så vill jag att fakturan skickas via mail.",
                type: ApplicationFieldType.SELECT,
                options: [
                    {text: "Ja", value: "1"},
                    {text: "Nej, jag vill ha fakturan via post", value: "0"}
                ],
                validationRegex: REGEX_NON_EMPTY
            },

            {
                key: "_caretakerCount",
                text: "Vårdnadshavare",
                type: ApplicationFieldType.FLAG,
                options: [
                    {text: "Båda", value: ""},
                    {text: "Ensam vårdnad", value: "1"}
                ],
            },
            {
                key: "relationToChild",
                text: "Ange vilken relation vårdnadshavarna har till barnet",
                type: ApplicationFieldType.TEXT,
                validationRegex: REGEX_NON_EMPTY,
            },
        ],
    },
    {
        key: "caretaker1",
        text: "Vårdnadshavare 1",
        fields: [
            {
                key: "firstName",
                text: "Förnamn",
                type: ApplicationFieldType.TEXT,
                validationRegex: REGEX_NON_EMPTY,
            },
            {
                key: "lastName",
                text: "Efternamn",
                type: ApplicationFieldType.TEXT,
                validationRegex: REGEX_NON_EMPTY
            },
            {
                key: "ssn",
                text: "Personnummer",
                type: ApplicationFieldType.TEXT,
                description: "Tolv siffror med bindestreck. Ex: 20000101-0000.",
                filterRegex: REGEX_FILTER_SSN,
                validationRegex: REGEX_SSN,
            },

            {key: "address", text: "Adress", type: ApplicationFieldType.TEXT, validationRegex: REGEX_NON_EMPTY},
            {key: "city", text: "Ort", type: ApplicationFieldType.TEXT, validationRegex: REGEX_NON_EMPTY},
            {
                key: "zipCode",
                text: "Postnummer",
                type: ApplicationFieldType.TEXT,
                validationRegex: REGEX_NON_EMPTY,
            },

            {key: "email", text: "Emailadress", type: ApplicationFieldType.TEXT, validationRegex: REGEX_EMAIL},
            {
                key: "phoneNumberPrivate",
                text: "Telefon Privat",
                type: ApplicationFieldType.TEXT,
                filterRegex: REGEX_FILTER_DIGITS,
                validationRegex: REGEX_NON_EMPTY
            },
            {
                key: "phoneNumberWork",
                text: "Telefon Arbete",
                type: ApplicationFieldType.TEXT,
                filterRegex: REGEX_FILTER_DIGITS
            },

            {
                key: "_incomeInfo1",
                text: "Vad räknas som inkomst?",
                type: ApplicationFieldType.INFO_BLOCK,
                description: "Den inkomst som avgiften grundas på är först och främst bruttoinkomst, dvs lön innan skatten dragits. Som inkomst räknas också:\n- sjukpenning\n- omskolningsbidrag\n- arbetslöshetsersättning\n- familjebidrag (ekonomisk hjälp vid värnplikt/militärtjänstgöring)\n- pensionsförmåner\n- bidragsdel av statligt studiemedel\n- underhållsbidrag/underhållsstöd\n- regelbundna bidrag från stiftelser och fonder\n- ränteinkomster över 800:-/år för samtaxerade och 400:-/år för ensamstående\n- familjehemsbarnsersättning, omkostnadsdelen frånräknas"
            },
            {
                key: "_incomeInfo2",
                text: "Som inkomst räknas inte",
                type: ApplicationFieldType.INFO_BLOCK,
                description: "- barnbidrag\n- bostadstillägg\n- socialhjälp\n- lånedelen av statligt studiemedel\n- invaliditetsersättning\n- vårdbidrag till handikappade barn\n- kommunalt bostadsstöd åt handikappade"
            },
            {
                key: "salary",
                text: "Månadslön Före Skatt",
                type: ApplicationFieldType.TEXT,
                filterRegex: REGEX_FILTER_DIGITS
            },
            {
                key: "parentBenefits",
                text: "Underhållsbidrag/Underhållsstöd Per Månad",
                type: ApplicationFieldType.TEXT,
                filterRegex: REGEX_FILTER_DIGITS
            },
            {
                key: "educationBenefits",
                text: "Utbildningsbidrag Per Månad",
                type: ApplicationFieldType.TEXT,
                filterRegex: REGEX_FILTER_DIGITS
            },
            {
                key: "studentBenefits",
                text: "Bidragsdelen Av Studiemedel Per Månad",
                type: ApplicationFieldType.TEXT,
                filterRegex: REGEX_FILTER_DIGITS
            },
            {
                key: "pension",
                text: "Pensionsförmåner (Även Barn) Per Månad",
                type: ApplicationFieldType.TEXT,
                filterRegex: REGEX_FILTER_DIGITS
            },
            {
                key: "miscIncome",
                text: "Övriga Inkomster Per Månad",
                type: ApplicationFieldType.TEXT,
                filterRegex: REGEX_FILTER_DIGITS
            },
        ],
    },
    {
        key: "caretaker2",
        text: "Vårdnadshavare 2",
        hideOnFlag: "_caretakerCount",
        fields: [
            {
                key: "firstName",
                text: "Förnamn",
                type: ApplicationFieldType.TEXT,
                validationRegex: REGEX_NON_EMPTY,
            },
            {
                key: "lastName",
                text: "Efternamn",
                type: ApplicationFieldType.TEXT,
                validationRegex: REGEX_NON_EMPTY,
            },
            {
                key: "ssn",
                text: "Personnummer",
                type: ApplicationFieldType.TEXT,
                description: "Tolv siffror med bindestreck. Ex: 20000101-0000.",
                filterRegex: REGEX_FILTER_SSN,
                validationRegex: REGEX_SSN
            },

            {key: "address", text: "Adress", type: ApplicationFieldType.TEXT, validationRegex: REGEX_NON_EMPTY},
            {key: "city", text: "Ort", type: ApplicationFieldType.TEXT, validationRegex: REGEX_NON_EMPTY},
            {
                key: "zipCode",
                text: "Postnummer",
                type: ApplicationFieldType.TEXT,
                filterRegex: REGEX_FILTER_DIGITS,
                validationRegex: REGEX_ZIPCODE
            },

            {key: "email", text: "Emailadress", type: ApplicationFieldType.TEXT, validationRegex: REGEX_NON_EMPTY},
            {
                key: "phoneNumberPrivate",
                text: "Telefon Privat",
                type: ApplicationFieldType.TEXT,
                filterRegex: REGEX_FILTER_DIGITS,
                validationRegex: REGEX_NON_EMPTY
            },
            {
                key: "phoneNumberWork",
                text: "Telefon Arbete",
                type: ApplicationFieldType.TEXT,
                filterRegex: REGEX_FILTER_DIGITS
            },

            {
                key: "_incomeInfo1",
                text: "Vad räknas som inkomst?",
                type: ApplicationFieldType.INFO_BLOCK,
                description: "Den inkomst som avgiften grundas på är först och främst bruttoinkomst, dvs lön innan skatten dragits. Som inkomst räknas också:\n- sjukpenning\n- omskolningsbidrag\n- arbetslöshetsersättning\n- familjebidrag (ekonomisk hjälp vid värnplikt/militärtjänstgöring)\n- pensionsförmåner\n- bidragsdel av statligt studiemedel\n- underhållsbidrag/underhållsstöd\n- regelbundna bidrag från stiftelser och fonder\n- ränteinkomster över 800:-/år för samtaxerade och 400:-/år för ensamstående\n- familjehemsbarnsersättning, omkostnadsdelen frånräknas"
            },
            {
                key: "_incomeInfo2",
                text: "Som inkomst räknas inte",
                type: ApplicationFieldType.INFO_BLOCK,
                description: "- barnbidrag\n- bostadstillägg\n- socialhjälp\n- lånedelen av statligt studiemedel\n- invaliditetsersättning\n- vårdbidrag till handikappade barn\n- kommunalt bostadsstöd åt handikappade"
            },
            {
                key: "salary",
                text: "Månadslön Före Skatt",
                type: ApplicationFieldType.TEXT,
                filterRegex: REGEX_FILTER_DIGITS
            },
            {
                key: "parentBenefits",
                text: "Underhållsbidrag/Underhållsstöd Per Månad",
                type: ApplicationFieldType.TEXT,
                filterRegex: REGEX_FILTER_DIGITS
            },
            {
                key: "educationBenefits",
                text: "Utbildningsbidrag Per Månad",
                type: ApplicationFieldType.TEXT,
                filterRegex: REGEX_FILTER_DIGITS
            },
            {
                key: "studentBenefits",
                text: "Bidragsdelen Av Studiemedel Per Månad",
                type: ApplicationFieldType.TEXT,
                filterRegex: REGEX_FILTER_DIGITS
            },
            {
                key: "pension",
                text: "Pensionsförmåner (Även Barn) Per Månad",
                type: ApplicationFieldType.TEXT,
                filterRegex: REGEX_FILTER_DIGITS
            },
            {
                key: "miscIncome",
                text: "Övriga Inkomster Per Månad",
                type: ApplicationFieldType.TEXT,
                filterRegex: REGEX_FILTER_DIGITS
            },
        ],
    },
    {
        key: "_endInfo",
        text: "Skicka in Ansökan",
        fields: [
            {
                key: "_privacyPolicy",
                text: "Personuppgiftsvillkor",
                type: ApplicationFieldType.INFO_BLOCK,
                description: "Vi behöver spara och behandla personuppgifter om er (vårdnadshavare) och ert barn, så som namn, personnummer, adress och ev. sjukdom.\nSyftet med en sådan behandling är för att skapa förutsättningar för ert barns deltagande på Säljö Barnkoloni.\nVi har fått era uppgifter från er ansökan på www.saljo.se vilka är nödvändiga för att ge ert barn rätt förutsättningar under sin kollovistelse.\nVi tillämpar vid var tid gällande integritetslagstiftning vid all behandling av personuppgifter. Den rättsliga grunden för att behandla dina personuppgifter är samtycke.\nDu har när som helst rätt att återkalla ditt samtycke till behandlingen. Ett återkallande påverkar inte lagligheten av behandlingen innan samtycket återkallades.\nDina uppgifter kommer att sparas som längst till september det år ni ansökt om plats på kollo.\nDe personuppgifter vi behandlar om dig delas med personuppgiftsansvarig. Vi kan även komma att dela dina personuppgifter med en tredje part,\nförutsatt att vi är skyldiga att göra så enligt lag. Däremot kommer vi aldrig att överföra dina uppgifter till ett land utanför EU.\nPersonuppgiftsansvarig är Cajam AB 556615-6518, info@cajam.se, 0455 – 103 30.\nDu har rätt att kontakta oss om du vill ha ut information om de uppgifter vi har om dig, för att begära rättelse,\növerföring eller för att begära att vi begränsar behandlingen, för att göra invändningar eller begära radering av dina uppgifter.\nDetta gör du enklast genom att kontakta oss på 0455 – 103 30. Du når vårt dataskyddsombud på 0455 – 103 30.\nOm du har klagomål på vår behandling av dina personuppgifter har du rätt att inge klagomål till tillsynsmyndigheten Datainspektionen.",
            }
        ]
    }
];
