import React, {useEffect, useState} from "react";
import {
    APPLICATION_FIELD_GROUPS,
    ApplicationField,
    ApplicationFieldGroup,
    ApplicationFieldType,
} from "./ApplicationFields";
import {ApplicationAPI} from "../../../api/ApplicationAPI";
import {useSearchParams} from "react-router-dom";

const GENERIC_ERROR_MESSAGE = "Ett fel uppstod. Försök igen senare.";
const VALIDATION_ERROR_MESSAGE = "Ett eller flera fält är felaktigt ifyllda.\n\nFelaktigt ifyllda fält är rödmarkerade.";

export const ApplicationPage = () => {
    const [error, setError] = useState("");
    const [data, setData] = useState<{
        [key: string]: { [key: string]: string };
    }>({});
    const [flags, setFlags] = useState<string[]>([]);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [agreementAccepted, setAgreeementAccepted] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [validationFails, setValidationFails] = useState<string[]>([]);
    const [caretaker1Sum, setCaretaker1Sum] = useState(0);
    const [caretaker2Sum, setCaretaker2Sum] = useState(0);
    const [success, setSuccess] = useState(false);
    const [queryParams] = useSearchParams();

    const overrideAccess = queryParams.get("overridePassword") === "Saljo2025!";

    useEffect(() => {
        const initialData: { [key: string]: { [key: string]: string } } = {};

        APPLICATION_FIELD_GROUPS.filter(group => !group.key.startsWith("_")).forEach((group) => {
            initialData[group.key] = {};

            group.fields.forEach((field) => {
                initialData[group.key][field.key] = "";
            });
        });

        setData(initialData);
    }, []);

    if (Object.keys(data).length === 0) {
        return null;
    }

    const setDataItem = (
        group: ApplicationFieldGroup,
        field: ApplicationField,
        value: string
    ) => {
        const newData = {...data};
        newData[group.key][field.key] = value;

        const incomeKeys = ["salary", "parentBenefits", "educationBenefits", "studentBenefits", "pension", "miscIncome"];

        for (const groupKey of ["caretaker1", "caretaker2"]) {
            const sum = incomeKeys.map(fieldKey => {
                const str = newData[groupKey][fieldKey].replace(/\D/gm, "") || "0";
                const parsed = parseInt(str, 10);

                return isNaN(parsed) ? 0 : parsed;
            }).reduce((acc, val) => acc + val);

            if (groupKey === "caretaker1") {
                setCaretaker1Sum(sum);
            } else {
                setCaretaker2Sum(sum);
            }
        }

        setData(newData);
    };

    const submitApplication = () => {
        if (!termsAccepted || !agreementAccepted || submitting) {
            return;
        }

        setSubmitting(true);

        const cleanData = {...data};
        const currentFails: string[] = [];

        for (const group of APPLICATION_FIELD_GROUPS.filter(group => !group.key.startsWith("_"))) {
            for (const field of group.fields) {
                let cleanStr = cleanData[group.key][field.key].trim();

                if (field.filterRegex) {
                    cleanStr = cleanStr.split("").filter(char => field.filterRegex?.test(char)).join("");
                }

                cleanData[group.key][field.key] = cleanStr;

                if (field.validationRegex && (!group.hideOnFlag || !flags.includes(group.hideOnFlag))) {
                    if (!field.validationRegex.test(cleanStr)) {
                        currentFails.push(`${group.key}.${field.key}`);
                    } else if (field.neq) {
                        if (cleanStr === cleanData[group.key][field.neq]) {
                            currentFails.push(`${group.key}.${field.key}`);
                        }
                    }
                }
            }
        }

        setValidationFails(currentFails);

        if (currentFails.length === 0) {
            const compatData = {
                "period-first": cleanData["attendee"]["periodPrimaryIndex"],
                "period-alternative": cleanData["attendee"]["periodSecondaryIndex"],
                "child-firstname": cleanData["attendee"]["firstName"],
                "child-lastname": cleanData["attendee"]["lastName"],
                "child-ssn": cleanData["attendee"]["ssn"],
                "child-school": cleanData["attendee"]["school"],
                "child-address": cleanData["attendee"]["address"],
                "child-zipcode": cleanData["attendee"]["zipCode"],
                "child-city": cleanData["attendee"]["city"],
                "child-previous": !!cleanData["attendee"]["applicationYears"],
                "child-previousyears": cleanData["attendee"]["applicationYears"],
                "child-attended": !!cleanData["attendee"]["attendanceYears"],
                "child-attendedyears": cleanData["attendee"]["attendanceYears"],
                "child-shirtsize": cleanData["attendee"]["shirtSize"],
                "child-okweb": cleanData["attendee"]["consentWeb"],
                "child-oksocial": cleanData["attendee"]["consentSocialMedia"],
                "child-medical": cleanData["attendee"]["medicalInfo"],
                "misc-info": cleanData["attendee"]["extraInfo"],
                "mail-sendfull": cleanData["attendee"]["sendApplicationCopy"],
                "invoice-mail": cleanData["attendee"]["invoiceMethod"],
                "caretaker-who": flags.includes("_caretakerCount") ? 0 : 1,
                "caretaker-relation": cleanData["attendee"]["relationToChild"],
                "parent1-firstname": cleanData["caretaker1"]["firstName"],
                "parent1-lastname": cleanData["caretaker1"]["lastName"],
                "parent1-ssn": cleanData["caretaker1"]["ssn"],
                "parent1-address": cleanData["caretaker1"]["address"],
                "parent1-zipcode": cleanData["caretaker1"]["zipCode"],
                "parent1-city": cleanData["caretaker1"]["city"],
                "parent1-telprivate": cleanData["caretaker1"]["phoneNumberPrivate"],
                "parent1-telwork": cleanData["caretaker1"]["phoneNumberWork"],
                "parent1-email": cleanData["caretaker1"]["email"],
                "parent1-salary": cleanData["caretaker1"]["salary"] || 0,
                "parent1-educationbenefits": cleanData["caretaker1"]["educationBenefits"] || 0,
                "parent1-studentbenefits": cleanData["caretaker1"]["studentBenefits"] || 0,
                "parent1-parentbenefits": cleanData["caretaker1"]["parentBenefits"] || 0,
                "parent1-pension": cleanData["caretaker1"]["pension"] || 0,
                "parent1-miscincome": cleanData["caretaker1"]["miscIncome"] || 0,
                "parent1-sumincome": caretaker1Sum || 0,
                "parent2-firstname": cleanData["caretaker2"]["firstName"],
                "parent2-lastname": cleanData["caretaker2"]["lastName"],
                "parent2-ssn": cleanData["caretaker2"]["ssn"],
                "parent2-address": cleanData["caretaker2"]["address"],
                "parent2-zipcode": cleanData["caretaker2"]["zipCode"],
                "parent2-city": cleanData["caretaker2"]["city"],
                "parent2-telprivate": cleanData["caretaker2"]["phoneNumberPrivate"],
                "parent2-telwork": cleanData["caretaker2"]["phoneNumberWork"],
                "parent2-email": cleanData["caretaker2"]["email"],
                "parent2-salary": cleanData["caretaker2"]["salary"] || 0,
                "parent2-educationbenefits": cleanData["caretaker2"]["educationBenefits"] || 0,
                "parent2-studentbenefits": cleanData["caretaker2"]["studentBenefits"] || 0,
                "parent2-parentbenefits": cleanData["caretaker2"]["parentBenefits"] || 0,
                "parent2-pension": cleanData["caretaker2"]["pension"] || 0,
                "parent2-miscincome": cleanData["caretaker2"]["miscIncome"] || 0,
                "parent2-sumincome": caretaker2Sum || 0
            };

            ApplicationAPI.registerApplication(compatData)
                .then(res => {
                    setSuccess(true);
                })
                .catch(error => {
                    console.error(error);
                    setError(GENERIC_ERROR_MESSAGE);
                })
                .finally(() => {
                    setSubmitting(false);
                })
        } else {
            setSubmitting(false);
            setError(VALIDATION_ERROR_MESSAGE);
            setTermsAccepted(false);
            setAgreeementAccepted(false);
        }
    }

    const renderInput = (group: ApplicationFieldGroup, field: ApplicationField) => {
        const isInvalid = validationFails.includes(`${group.key}.${field.key}`);
        const validClass = isInvalid ? "is-invalid" : "";

        switch (field.type) {
            case ApplicationFieldType.TEXT:
                return (
                    <input
                        type="text"
                        className={`form-control ${validClass}`}
                        required={!!field.validationRegex}
                        value={data[group.key][field.key]}
                        onChange={(evt) => setDataItem(group, field, evt.target.value)}
                    />
                );
            case ApplicationFieldType.MULTILINE:
                return (
                    <textarea
                        className={`form-control ${validClass}`}
                        rows={10}
                        value={data[group.key][field.key]}
                        onChange={(evt) => setDataItem(group, field, evt.target.value)}
                    />
                );
            case ApplicationFieldType.CHECKBOXES:
                return (
                    <>
                        {field.options?.map((option) => (
                            <div className="form-check" key={option.value}>
                                <input
                                    type="checkbox"
                                    className={`form-check-input ${validClass}`}
                                    checked={data[group.key][field.key]
                                        .split(",")
                                        .includes(option.value)}
                                    onChange={(evt) => {
                                        let current = data[group.key][field.key].split(",");

                                        if (evt.target.checked) {
                                            current.push(option.value);
                                        } else {
                                            current = current.filter((elem) => elem !== option.value);
                                        }

                                        setDataItem(group, field, current.filter(str => str !== "").join(","));
                                    }}
                                />
                                <label className="form-check-label">{option.text}</label>
                            </div>
                        ))}
                    </>
                );
            case ApplicationFieldType.SELECT:
                return (
                    <fieldset>
                        {field.options?.map((option) => (
                            <div className="form-check" key={option.value}>
                                <input
                                    type="radio"
                                    name={field.key}
                                    className={`form-check-input ${validClass}`}
                                    checked={data[group.key][field.key] === option.value}
                                    onChange={(evt) => setDataItem(group, field, option.value)}
                                />
                                <label className="form-check-label">{option.text}</label>
                            </div>
                        ))}
                    </fieldset>
                );
            case ApplicationFieldType.FLAG:
                return (
                    <fieldset>
                        {field.options?.map((option) => (
                            <div className="form-check" key={option.value}>
                                <input
                                    type="radio"
                                    name={field.key}
                                    className={`form-check-input ${validClass}`}
                                    checked={
                                        (!!option.value && flags.includes(field.key)) ||
                                        (!option.value && !flags.includes(field.key))
                                    }
                                    onChange={(evt) => {
                                        let current = [...flags];

                                        if (!!option.value) {
                                            current.push(field.key);
                                        } else {
                                            current = current.filter((elem) => elem !== field.key);
                                        }

                                        setFlags(current);
                                    }}
                                />
                                <label className="form-check-label">{option.text}</label>
                            </div>
                        ))}
                    </fieldset>
                );
            case ApplicationFieldType.INFO_BLOCK:
                return null;
            default:
                console.error("Invalid field type:", field.type);
                return null;
        }
    }

    const renderField = (
        group: ApplicationFieldGroup,
        field: ApplicationField
    ) => {
        const isInvalid = validationFails.includes(`${group.key}.${field.key}`);

        return (
            <div className="form-group mt-3">
                <label>{field.text} {!!field.validationRegex && "(*)"}</label>
                {!!field.description && (
                    <>
                        <br/>
                        <small className="text-muted">
                            <pre>{field.description}</pre>
                        </small>
                    </>
                )}
                {renderInput(group, field)}
                {isInvalid && (
                    <div className="invalid-feedback d-block">
                        Fältet är tomt eller felaktigt ifyllt.
                    </div>
                )}
            </div>
        );
    };

    if (success) {
        return (
            <div className="application-page mb-5">
                <div className="bg-primary w-100 p-3 mb-5 text-center">
                    <img className="mb-2" src="/images/logo.svg" alt="Säljö Barnkoloni" style={{height: 100}}/>
                    <h1 style={{color: "#ffffff"}}>Ansökan till Säljö Barnkoloni 2025</h1>
                </div>

                <div className="container">
                    <p>
                        Din ansökan har mottagits. Besked om deltagande skickas ut senast vecka 18.
                    </p>
                </div>
            </div>
        );
    }

    if (overrideAccess) {
        return (
            <>
                <div className="application-page mb-5">
                    <div className="bg-primary w-100 p-3 mb-5 text-center">
                        <img className="mb-2" src="/images/logo.svg" alt="Säljö Barnkoloni" style={{height: 100}}/>
                        <h1 style={{color: "#ffffff"}}>Ansökan till Säljö Barnkoloni 2025</h1>
                    </div>

                    <div className="container">
                        <a href="https://www.saljo.se/sommarkollo/information/" target="_blank" rel="noreferrer">
                            Läs mer om Säljö Barnkoloni här.
                        </a>
                        <br/>
                        <br/>
                        {APPLICATION_FIELD_GROUPS.filter(
                            (group) => !group.hideOnFlag || !flags.includes(group.hideOnFlag)
                        ).map((group) => {
                            return (
                                <div className="mb-5" key={group.key}>
                                    <h2>{group.text}</h2>
                                    {group.fields.map((field) => (
                                        <React.Fragment key={`${group.key}.${field.key}`}>
                                            {renderField(group, field)}
                                        </React.Fragment>
                                    ))}
                                </div>
                            );
                        })}
                        <strong>Godkännande</strong>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={termsAccepted}
                                onChange={(evt) => setTermsAccepted(evt.target.checked)}
                            />
                            <label className="form-check-label">Härmed godkänner jag att Cajam AB lagrar mina
                                personuppgifter
                                enligt villkoren ovan.</label>
                        </div>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={agreementAccepted}
                                onChange={(evt) => setAgreeementAccepted(evt.target.checked)}
                            />
                            <label className="form-check-label">Härmed försäkras att de följande, av mig lämnade
                                uppgifterna
                                är
                                sanningsenliga.</label>
                            <div>
                                <small>
                            <pre>
                                - Ovan ifyllda uppgifter<br/>
                                - Total månadsinkomst för {data["caretaker1"]["firstName"] || "Vårdnadshavare 1"} är {caretaker1Sum || 0} SEK<br/>
                                {!flags.includes("_caretakerCount") && `- Total månadsinkomst för ${data["caretaker2"]["firstName"] || "Vårdnadshavare 2"} är ${caretaker2Sum || 0} SEK.`}
                            </pre>
                                </small>
                            </div>
                        </div>
                        <br/>
                        <button type="button" className="btn btn-primary"
                                disabled={!termsAccepted || !agreementAccepted || submitting}
                                onClick={submitApplication}>Skicka
                            in
                            ansökan
                        </button>
                        {(!termsAccepted || !agreementAccepted) && (
                            <div className="invalid-feedback d-block">
                                Du måste godkänna personuppgiftspolicyn och försäkran om sanningsenliga uppgifter för
                                att
                                kunna
                                skicka in ansökan.
                            </div>
                        )}
                    </div>
                    {!!error && (
                        <div style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#000000b0",
                            zIndex: 99999,
                            textAlign: "center",
                        }}>
                            <div className="card w-50 mx-auto" style={{marginTop: "20vh"}}>
                                <h5 className="card-header">Säljö Barnkoloni</h5>
                                <div className="card-body" style={{minHeight: "25vh"}}>
                                    <pre>{error}</pre>
                                </div>
                                <div className="card-footer">
                                    <button type="button" className="btn btn-primary" onClick={() => setError("")}>
                                        Tillbaka
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    }

    return (
        <div className="application-page mb-5">
            <div className="bg-primary w-100 p-3 mb-5 text-center">
                <img className="mb-2" src="/images/logo.svg" alt="Säljö Barnkoloni" style={{height: 100}}/>
                <h1 style={{color: "#ffffff"}}>Ansökan till Säljö Barnkoloni 2025</h1>
            </div>

            <div className="container">
                <p>Ansökan till Säljö Barnkoloni är inte öppen.</p>
            </div>
        </div>
    );
};
